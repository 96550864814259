
.youtubeContainer {
    height: 580px;
}

.youtubeContainer iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

@media screen and (max-width: 560px) {

    .youtubeContainer {
        width: 350px;
    }

    .youtubeContainer iframe {
        padding-bottom: 56.25%;
    }

}